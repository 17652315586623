import api from '../library/axios';

export default {
  getList(workspace_id, page, search) {
    return api({
      method: 'get',
      url: `/api/channels/${workspace_id}`,
      params: {
        page,
        search,
      },
    });
  },
  createChannel(workspace_id, payload) {
    return api({
      method: 'post',
      url: `/api/channels/${workspace_id}`,
      data: payload,
    });
  },
  delete(workspace_id, channel_id) {
    return api({
      method: 'delete',
      url: `/api/channels/${workspace_id}/${channel_id}`,
    });
  },
  templateList({
    workspace_id,
    channel_id,
  }) {
    return api({
      url: `/api/broadcast/templates/${workspace_id}/${channel_id}`,
    });
  },
  checkTelegramProfile({
    type,
    token,
    workspace_id,
  }) {
    return api({
      method: 'post',
      url: `/api/channels/${workspace_id}/inquiry`,
      data: {
        type,
        token,
      },
    });
  },
  initLinkAge({ token }) {
    return api({
      method: 'post',
      url: '/api/wa-embed-signup/init',
      data: {
        token,
      },
    });
  },
  getEmbedPhoneNumber() {
    return api({
      url: '/api/wa-embed-signup/phone-numbers',
    });
  },
  getChannelProfile({
    channel_id,
    workspace_id,
  }) {
    return api({
      url: `/api/channels/${workspace_id}/${channel_id}/profile`,
    });
  },
  updateChannelProfile({
    channel_id,
    workspace_id,
    data,
  }) {
    return api({
      method: 'patch',
      url: `/api/channels/${workspace_id}/${channel_id}/profile`,
      data,
    });
  },
  getBlogs({
    workspace_id,
    channel_id,
    page,
    search,
  }) {
    return api({
      method: 'get',
      url: `/api/channels/${workspace_id}/${channel_id}/blog`,
      params: {
        page,
        search,
      },
    });
  },
  createBlog(workspace_id, channel_id, payload) {
    return api({
      method: 'post',
      url: `/api/channels/${workspace_id}/${channel_id}/blog`,
      data: payload,
    });
  },
  editBlog(workspace_id, channel_id, payload, id) {
    return api({
      method: 'patch',
      url: `/api/channels/${workspace_id}/${channel_id}/blog/${id}`,
      data: payload,
    });
  },
  deleteBlog(workspace_id, channel_id, id) {
    return api({
      method: 'delete',
      url: `/api/channels/${workspace_id}/${channel_id}/blog/${id}`,
    });
  },
  publishBlog(workspace_id, channel_id, id, status) {
    return api({
      method: 'patch',
      url: `/api/channels/${workspace_id}/${channel_id}/blog/publish/${id}`,
      data: {
        status,
      },
    });
  },
  initLinkAgeInstagram({ token }) {
    return api({
      method: 'post',
      url: '/api/instagram-embed-signup/init',
      data: {
        token,
      },
    });
  },
  getChannelFeed({
    channel_id,
    workspace_id,
    query,
  }) {
    return api({
      url: `/api/channels/${workspace_id}/${channel_id}/feed`,
      params: query,
    });
  },
  initLinkAgeFacebookMessenger({ token }) {
    return api({
      method: 'post',
      url: '/api/fb-messenger-embed-signup/init',
      data: {
        token,
      },
    });
  },
};
