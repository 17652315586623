<template>
  <div class="page-content">
    <b-card no-body>
      <b-tabs card>
        <b-tab title="Profile" active>
          <b-card-text>
            <edit-whatsapp v-if="channel_type === 'whatsapp'" ref="whatsapp_component" />
            <edit-webchat v-if="channel_type === 'webchat'" ref="webchat_component" />
            <InstagramProfile v-if="channel_type == 'instagram'" :profile="profile"></InstagramProfile>
          </b-card-text>
        </b-tab>
        <b-tab title="Blog" v-if="channel_type === 'webchat'">
          <b-card-text>
            <blog-webchat v-if="channel_type === 'webchat'" ref="webchat_component" />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>
<script>
import useVuelidate from '@vuelidate/core';
import {
  email, maxLength, url, helpers,
} from '@vuelidate/validators';
// import popupErrorMessages from '../../../library/popup-error-messages';
// import 'babel-polyfill';
// import ImageCrop from 'vue-image-crop-upload/upload-2.vue';
import channelsAPI from '../../../api/channels';
import InstagramProfile from '../../components/channels/ProfileInstagram.vue';
import EditWhatsapp from '../../components/channels/EditWhatsapp.vue';
import EditWebchat from '../../components/channels/EditWebchat.vue';
import BlogWebchat from '../../components/channels/Blogs.vue';

export default {
  name: 'ChannelProfile',
  metaInfo: {
    title: 'Channel Profile',
  },
  components: {
    // ImageCrop,
    InstagramProfile,
    // ImageCrop,
    EditWhatsapp,
    EditWebchat,
    BlogWebchat,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form_profile: {
        email: { email, maxLength: maxLength(128) },
        about: { maxLength: maxLength(256) },
        address: { maxLength: maxLength(256) },
        description: { maxLength: maxLength(512) },
        websites: {
          maxLength: maxLength(2),
          $each: helpers.forEach({
            url: {
              url,
              maxLength: maxLength(256),
            },
          }),
        },
      },
    };
  },
  data() {
    return {
      channel_type: '',
      channel_obj: {
        name: '',
      },
      channel_list: [
        { name: 'WhatsApp', icon: 'mdi mdi-whatsapp', value: 'whatsapp' },
        { name: 'Telegram', icon: 'mdi mdi-telegram', value: 'telegram' },
        { name: 'Email', icon: 'mdi mdi-email', value: 'email' },
        { name: 'Instagram', icon: 'mdi mdi-instagram', value: 'instagram' },
      ],
      loading: {
        update_profile: false,
      },
      form_profile: {
        about: '',
        address: '',
        description: '',
        email: '',
        websites: [],
        base64_profile: '',
      },
      selected_file_profile: null,
      showCropImage: false,
      loaderStack: 0,
      loader: null,
      profile: {},
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    this.getChannelProfile();
  },
  methods: {
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    toggleShow() {
      this.showCropImage = !this.showCropImage;
    },
    // cropSuccess(imgDataUrl, field){
    cropSuccess(imgDataUrl) {
      console.log('-------- crop success --------');
      // this.imgDataUrl = imgDataUrl;
      this.form_profile.base64_profile = imgDataUrl;
    },
    async getChannelProfile() {
      this.showLoader();
      await channelsAPI.getChannelProfile({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.$route.params.channel_id,
      }).then(({ data }) => {
        const { channel_type, profile } = data;
        this.channel_type = channel_type;
        this.channel_obj = this.channel_list.find((value) => value.value === channel_type) || {};
        Object.keys(this.form_profile).forEach((key) => {
          if (key === 'websites' && profile[key]) {
            this.$set(this.form_profile, key, profile[key].map((v) => ({ url: v })));
          } else {
            this.$set(this.form_profile, key, profile[key]);
          }
        });
        this.profile = profile;
        this.form_profile = profile;
        if (profile.photo) {
          this.profile.profile_picture_url = profile.photo;
        }
        if (channel_type === 'whatsapp') {
          this.$nextTick(() => {
            this.$refs.whatsapp_component.getChannelProfile();
          });
        } else if (channel_type === 'webchat') {
          this.$nextTick(() => {
            this.$refs.webchat_component.getChannelProfile();
          });
        }
        console.log(this.channel_type);
      }).catch((err) => console.log(err));
      this.hideLoader();
    },
  },
};
</script>
