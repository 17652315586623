<template>
  <div>
    <div class="row">
      <div class="col-lg-8 card-body">
        <h5>@{{ profile.username }}</h5>
        <div class="row mt-2">
          <div class="col"><b>{{ profile.media_count }}</b> posts</div>
          <div class="col"><b>{{ profile.follows_count }}</b> following</div>
          <div class="col"><b>{{ profile.followers_count }}</b> follower</div>
        </div>
        <div class="mt-2">
          <h6>{{ profile.name }}</h6>
        </div>
        <div class="mt-2">
          <span>{{ profile.biography }}</span>
        </div>
        <div class="mt-2">
          <a :href="profile.website" target="blank"><font-awesome icon="link"/> {{ profile.website }}</a>
        </div>
        <!-- {{ profile }} -->
      </div>
      <div class="col-lg-4">
        <b-avatar size="150" :src="profile.profile_picture_url" alt="profile"/>
      </div>
    </div>
    <hr/>
    <div class="row" v-loading="loading_post">
      <div class="col-lg-4 cursor-pointer" @click="openPost(post)" v-for="post in posts" :key="post.id">
        <!-- <a :href="post.permalink" target="_blank"> -->
          <div class="feed-placeholder">
            <el-image :src="post.media_url" style="max-width: 350px; max-height: 350px">
              <div slot="placeholder" class="image-slot">
                Loading<span class="dot">...</span>
              </div>
            </el-image>
            <div class="feed-hover">
              <div class="row">
                <div class="col-lg-6">
                  <font-awesome icon="heart" class="text-danger"/> {{ post.like_count }}
                </div>
                <div class="col-lg-6">
                  <font-awesome icon="comment" class="text-white"/> {{ post.comments_count }}
                </div>
              </div>
            </div>
          </div>
        <!-- </a> -->
      </div>
    </div>
    <div align="center">
      <a @click="loadInstagramFeed(after_token)" href="javascript:void(0)">Load more</a>
    </div>
    <!-- <div class="card-footer">
      <el-button type="info" @click="$router.back()" size="small" class="float-right">Back</el-button>
    </div> -->
  </div>
</template>
<script>
import channels from '../../../api/channels';

export default {
  name: 'ProfileInstagram',
  props: {
    profile: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      loading_post: false,
      posts: [],
      after_token: '',
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  mounted() {
    this.loadInstagramFeed();
  },
  methods: {
    loadInstagramFeed(token) {
      this.loading_post = true;
      channels.getChannelFeed({
        workspace_id: this.activeWorkspace._id,
        channel_id: this.$route.params.channel_id,
        query: {
          after_token: token,
        },
      }).then(({ data }) => {
        this.loading_post = false;
        if (token) {
          this.posts.push(...data.posts);
        } else {
          this.posts = data.posts;
        }
        this.after_token = data.after_token;
        // console.log(data);
      }).catch((err) => {
        this.loading_post = false;
        console.log(err);
      });
    },
    openPost(post) {
      window.open(post.permalink, '_blank');
    },
  },
};
</script>
<style>
.feed-placeholder:hover {
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10;
}
.feed-hover {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
.feed-placeholder:hover .feed-hover {
  opacity: 1;
}
.feed-placeholder:hover img {
  opacity: 0.3;
}
</style>
