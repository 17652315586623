<template>
  <div>
    <div class="row d-flex align-items-center">
      <!-- <div class="col-6 col-sm-6">
        <form class="search-form w-100" @submit.prevent="handlerSearch()">
          <div class="input-group border rounded-sm">
            <div class="input-group-prepend">
              <div class="input-group-text border-0 rounded-sm">
                <FeatherIcon type="search" class="icon-md cursor-pointer" />
              </div>
            </div>
            <input v-model="search.search_keyword" type="text" class="form-control  border-0 rounded-sm" id="searchForm" placeholder="Search here by division name...">
          </div>
        </form>
      </div> -->
      <div class="col-12 col-sm-12 mb-2 mt-2">
        <router-link class="btn btn-primary" :to="addBlog">Add Blog</router-link>
      </div>
    </div>
    <div class="table-responsive mt-2" v-loading="loading.table">
      <table class="table table-hover">
        <thead>
          <tr>
            <th>Header Name</th>
            <th>Sub Header</th>
            <th>Created Date</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in rows" :key="item.id">
            <td style="word-wrap: break-word; min-width: 250px; white-space: initial;">{{ item.header }}</td>
            <td style="word-wrap: break-word; min-width: 300px; white-space: initial;">{{ item.sub_header }}</td>
            <td>{{ item.created_at_str }}</td>
            <td style="word-wrap: break-word; min-width: 200px; white-space: initial;">
              {{ item.published_at_str }}
            </td>
            <td>
              <button class="btn btn-outline-primary btn-sm mr-2" @click="handlerBtnPublish(item)">
                <font-awesome icon="desktop"></font-awesome>
              </button>
              <router-link class="btn btn-outline-primary btn-sm mr-2" :to="{ name: 'Edit Blog', params: item }">
                <font-awesome icon="edit"></font-awesome>
              </router-link>
              <button class="btn btn-outline-primary btn-sm" @click="handlerBtnDelete(item._id)">
                <font-awesome icon="times"></font-awesome>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <el-empty v-if="!total" description="No data"></el-empty>
      <b-pagination v-if="total > 20" v-model="currentPage" :total-rows="total" :per-page="20"/>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
// import { isEqual } from 'lodash';
import channelsAPI from '../../../api/channels';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'Blogs',
  metaInfo: {
    title: 'Blogs',
  },
  data() {
    return {
      loading: {
        table: false,
      },
      search: {
        page: this.$route.query.page || 1,
        search_keyword: this.$route.query.search_keyword || '',
      },
      rows: [],
      total: 0,
      loaderStack: 0,
      loader: null,
      selected_group: {},
    };
  },
  async mounted() {
    this.getList();
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
    addBlog() {
      return `/settings/channel/${this.$route.params.channel_id}/add/blog`;
    },
    currentPage: {
      get() {
        return this.search.page;
      },
      set(val) {
        this.search.page = val;
        this.getList();
      },
    },
  },
  methods: {
    editBlog(data) {
      return `/settings/channel/${this.$route.params.channel_id}/edit/blog/${data._id}`;
    },
    showLoader() {
      if (this.loaderStack === 0) {
        this.loader = this.$loading.show();
      }
      this.loaderStack += 1;
    },
    hideLoader() {
      this.loaderStack -= 1;
      if (this.loaderStack === 0) {
        this.loader.hide();
      }
    },
    handlerSearch() {
      this.search.page = 1;
      this.getList();
    },
    async getList() {
      this.loading.table = true;
      const options = {
        page: this.search.page.toString(),
        workspace_id: this.activeWorkspace._id,
        channel_id: this.$route.params.channel_id,
        search: this.search.search_keyword.toString(),
      };
      // if (!isEqual(options, this.$route.query)) {
      //   this.$router.replace({ query: options });
      // }
      await channelsAPI.getBlogs(options).then(({ data }) => {
        this.loading.table = false;
        popupErrorMessages(data);
        this.rows = data.rows.map((v) => {
          v.created_at_str = moment(v.created_at).format('DD MMM YYYY, HH:mm');
          v.published_at_str = v.published_at ? `Published at ${moment(v.published_at).format('DD MMM YYYY, HH:mm')}` : '';
          return v;
        });
        this.total = data.total;
      }).catch((err) => console.log(err));
    },
    handlerBtnDelete(id) {
      this.$confirm(this.$t('channels.blogs.confirm_delete'), this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await channelsAPI.deleteBlog(this.activeWorkspace._id, this.$route.params.channel_id, id)
              .then(async (response) => {
                await popupErrorMessages(response);
                this.$message({
                  message: this.$t('channels.blogs.success_delete'),
                  type: 'success',
                });
                this.search.page = 1;
                this.search.search_keyword = '';
                this.getList();
              });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    handlerBtnPublish(item) {
      // console.log(item);
      let confirm_str = '';
      let success_str = '';
      if (item.published_at === null) {
        confirm_str = this.$t('channels.blogs.confirm_publish');
        success_str = this.$t('channels.blogs.success_publish');
      } else {
        confirm_str = this.$t('channels.blogs.confirm_unpublish');
        success_str = this.$t('channels.blogs.success_unpublish');
      }
      this.$confirm(confirm_str, this.$t('general.confirmation'), {
        confirmButtonText: this.$t('general.yes'),
        cancelButtonText: this.$t('general.no'),
        type: 'warning',
        center: true,
        /* eslint-disable no-param-reassign */
        beforeClose: async (action, instance, cb) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            await channelsAPI.publishBlog(
              this.activeWorkspace._id,
              this.$route.params.channel_id,
              item._id,
              item.published_at,
            ).then(async (response) => {
              await popupErrorMessages(response);
              this.$message({
                message: success_str,
                type: 'success',
              });
              this.search.page = 1;
              this.search.search_keyword = '';
              this.getList();
            });
          }
          instance.confirmButtonLoading = false;
          cb();
        },
      }).catch(() => {});
    },
    // handlerViewBtn(id) {
    //   this.$router.push({
    //     name: 'Division Members',
    //     params: {
    //       id,
    //     },
    //   });
    // },
  //   handlerEditBtn(item) {
  //     this.selected_group = item;
  //     this.$set(this.show_modal, 'edit', true);
  //   },
  //   onHideModalEdit() {
  //     // this.show_modal.edit = false;
  //     this.$set(this.show_modal, 'edit', false);
  //   },
  },
};
</script>
